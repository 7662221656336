var config = {
  // Set the Demo Version
  demo: false,

  //SEO Configurations
  appName: "ITCerts - Certificaciones IT",
  metaDescription: "Plataforma de certificaciones IT gratis para practicar exámenes reales.",
  metaKeyWords: "itcerts, certificaciones it, it certifications, azure, microsoft, az-900",

  //API Configurations
  apiAccessKey: 8525,
  apiUrl: "https://itcertsdev.vayesa.com/Api/",

  //Language Configurations
  // Get Your Language Codes ---> https://developers.google.com/admin-sdk/directory/v1/languages
  supportedLanguages: ["en", "hi", "ur", "es"],
  defaultLanguage: "es",

  // If your Default Language is not in supportedLanguages then add there first and after that set the defaultLanguage.

  //Quiz Configurations
  deductReviewAnswerCoins: 10, // 10 coins will be deducted if user Review the Answer
  deductIncorrectAnswerScore: 1, // This will deduct the points if user will give wrong answer
  deductLifeLineCoins: 1, // Deduct Coins when using Life Line

  // default country selection Configurations
  DefaultCountrySelectedInMobile: "es", //Default Country Selected in Mobile Login Screen

  // guess the word Quiz Configurations
  Guessthewordhintcoin: 5, //deduct coins guess the word

  // 1 vs 1 battle Quiz Configurations
  battlecorrectanswer: 4,
  randomBattleSeconds:30, // 1 vs 1 battle timer seconds
  Randombattlecoins: 5, //deduct coins 1 vs 1 battle
  randomBattleoneToTwoSeconds: 2, // quick answer :- 1 vs 1 battle points 2 added on first 1 & 2 seconds
  randomBattlethreeToFourSeconds: 1, // quick answer :- 1 vs 1 battle points 1 added on first 3 & 4 seconds


  apiKey: "AIzaSyCYO5W7nlwbieUHujzdRtuJBbQZ4bgp-d4",
  authDomain: "itcerts-96a2b.firebaseapp.com",
  databaseURL: "https://itcerts-96a2b-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "itcerts-96a2b",
  storageBucket: "itcerts-96a2b.appspot.com",
  messagingSenderId: "68396386738",
  appId: "1:68396386738:web:64ab1da8c09fb98f797cab",
  measurementId: "G-4MWDX58NF0",

  //footer area
  companytext: "ITCerts es una plataforma para practicar tests de Certificaciones IT con preguntas de exámenes reales.",
  addresstext: "",
  phonenumber: "",
  email: "hola@itcerts.app",
  facebooklink: "",
  instagramlink: "",
  linkedinlink: "",
  weblink: "https://itcerts.app",
  companyname: "ITCerts.",
};

export default config;
